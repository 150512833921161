// SINGLETON

let instance: Storage | null;

// exporting only for testing
export class AuthLocalStorageWrapper {
  private _prefix: string;

  private _serialize(val: object) {
    return JSON.stringify(val);
  }

  private _deSerialize(val: string) {
    try {
      return JSON.parse(val);
    } catch (err) {
      // not an object so return the string
      return val;
    }
  }

  constructor(prefix: string) {
    if (instance) {
      throw new Error("You can only create one instance!");
    }
    this._prefix = prefix;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  // only exposing for test to confirm set up
  getPrefix() {
    return this._prefix;
  }

  setItem(key: string, val: string | object) {
    if (typeof val === "object") {
      const serializedVal = this._serialize(val);
      return window.localStorage.setItem(`${this._prefix}/${key}`, serializedVal);
    }
    return window.localStorage.setItem(`${this._prefix}/${key}`, val);
  }

  getItem(key: string) {
    const val = window.localStorage.getItem(`${this._prefix}/${key}`);
    if (val) {
      return this._deSerialize(val);
    }
    return val;
  }

  removeItem(key: string) {
    return window.localStorage.removeItem(`${this._prefix}/${key}`);
  }

  /**
   * instead of calling "clear" and removing all localStorage keys
   * example:
   *  clear() { return window.localStorage.clear(); }
   *
   * only delete keys that start with the prefix
   */
  clear() {
    for (const key in window.localStorage) {
      if (key.startsWith(`${this._prefix}`)) {
        window.localStorage.removeItem(key);
      }
    }
  }

  // the following are methods required by the Storage TS type
  // note: not scoped to the prefix
  key(index: number) {
    return window.localStorage.key(index);
  }

  // the following are properties required by the Storage TS type
  // note: not scoped to the prefix
  get length() {
    return window.localStorage.length;
  }
}

// const singletonStorage = Object.freeze(new AuthLocalStorageWrapper("__swingsubsdb"));
const singletonStorage = new AuthLocalStorageWrapper("__swingsubsdb");
export default singletonStorage;
