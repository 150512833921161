import { jsx as n, jsxs as s } from "react/jsx-runtime";
import { IonItem as _ } from "@ionic/react";
import { I as m } from "../../../Icon-p8J22jb2.js";
import "../../../AuthProvider-ykImoPqe.js";
import "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import '../../../css/DataItemView.css';const g = "_icon_7f3w7_54", f = "_label_7f3w7_58", p = "_children_7f3w7_70", i = {
  "data-item": "_data-item_7f3w7_1",
  "data-grid-icon": "_data-grid-icon_7f3w7_6",
  "data-grid-icon-stacked": "_data-grid-icon-stacked_7f3w7_22",
  "data-grid-no-icon": "_data-grid-no-icon_7f3w7_30",
  "data-grid-no-icon-stacked": "_data-grid-no-icon-stacked_7f3w7_46",
  icon: g,
  label: f,
  children: p
};
function N(o) {
  const {
    icon: a,
    iconColor: t,
    label: c,
    lines: e,
    stackLayout: d,
    children: r,
    style: l
  } = o;
  return /* @__PURE__ */ n(_, {
    lines: e || "none",
    className: `${i["data-item"]} ion-no-padding`,
    style: {
      ...l
    },
    children: /* @__PURE__ */ s("div", {
      className: `${a ? i["data-grid-icon"] : i["data-grid-no-icon"]} ${d && a ? i["data-grid-icon-stacked"] : d && !a ? i["data-grid-no-icon-stacked"] : ""}`,
      children: [a ? /* @__PURE__ */ n("div", {
        className: `${i.icon}`,
        children: /* @__PURE__ */ n(m, {
          name: a,
          color: t
        })
      }) : null, /* @__PURE__ */ n("div", {
        className: i.label,
        style: {
          gridColumnStart: a ? 2 : void 0,
          gridColumn: d ? "span 2" : void 0
        },
        children: c
      }), /* @__PURE__ */ n("div", {
        className: i.children,
        children: r
      })]
    })
  });
}
export {
  N as DataItemView
};
