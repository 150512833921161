import { ActionSheet } from "@capacitor/action-sheet";
import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { IonText } from "@ionic/react";
import Markdown from "react-markdown";
import { Icon } from "swing-components";

import { Card } from "~components";
import {
  formatCityStateZip,
  generateGoogleMapsLink,
  handleMapLinkNavigation,
  msg,
  openLinkInApp,
  SchoolProfileData,
} from "~utils";
import styles from "./SubMarkdown.module.css";

type SchoolProfileSummaryProps = {
  name: string;
  address: SchoolProfileData["address"];
  websiteUrl?: string;
  summary?: string;
};

export function SchoolProfileSummary(props: SchoolProfileSummaryProps) {
  const { name, address, websiteUrl, summary } = props;

  return (
    <Card isBorderRadius isBoxShadow={false}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2 style={{ margin: "0" }}>{name}</h2>
      </div>
      {!!address && (
        <div style={{ display: "flex", gap: "16px", width: "100%" }}>
          <Icon name="Location" />
          <a
            style={{ display: "flex", flexDirection: "column" }}
            className="swing-link"
            target="_blank"
            rel="noreferrer"
            href={generateGoogleMapsLink(address)}
            onClick={(e) =>
              handleMapLinkNavigation(e, {
                getPlatform: Capacitor.getPlatform,
                appLauncher: AppLauncher,
                actionSheet: ActionSheet,
                openLinkInApp: openLinkInApp,
                query: encodeURIComponent(
                  `${address.street} ${address.city}, ${address.state}, ${address.zip}`,
                ),
                link: generateGoogleMapsLink(address),
              })
            }
          >
            <div>
              {address.street && (
                <IonText className={styles["truncate-one-line"]}>{address.street}</IonText>
              )}
              <IonText className={styles["truncate-one-line"]}>
                {formatCityStateZip(address)}
              </IonText>
            </div>
          </a>
        </div>
      )}

      {!!websiteUrl && (
        <div style={{ display: "flex", gap: "16px", margin: "16px 0" }}>
          <Icon name="Website" />
          {/* This will not open the link in app, not sure if that is wanted behavior given the content */}
          <a className="swing-link" rel="noopener noreferrer" href={websiteUrl}>
            {websiteUrl}
          </a>
        </div>
      )}

      <SubMarkdown label={msg("SCHOOL_SUMMARY_LABEL")} value={summary} />
    </Card>
  );
}

type SubMarkdownProps = {
  value?: string;
  label?: string;
};

export function SubMarkdown(props: SubMarkdownProps) {
  const { value, label } = props;
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", width: "100%", gap: "16px", paddingBottom: "16px" }}>
        <div style={{ padding: "0 16px" }}>
          <p style={{ fontWeight: "var(--swing-font-semibold)" }}>{label}</p>
          {value ? (
            <Markdown className={styles.markdownViewer}>{value}</Markdown>
          ) : (
            <span className="italic">{msg("NOT_PROVIDED")}</span>
          )}
        </div>
      </div>
    </div>
  );
}
