import { jsx as e, jsxs as i, Fragment as a } from "react/jsx-runtime";
import _ from "../../../../../string-table.js";
import { k as c } from "../../../../../../AuthProvider-ykImoPqe.js";
import { I as S } from "../../../../../../Icon-p8J22jb2.js";
import "@ionic/react";
import { useState as N } from "react";
import "../../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { Accordion as T } from "../../../../Acccordion/Accordion.js";
import '../../../../../../css/SwingHeroInEligible.css';const d = "_container_11hhc_1", h = "_divider_11hhc_10", m = "_title_11hhc_16", R = "_list_11hhc_23", G = "_listTitle_11hhc_28", O = "_listItem_11hhc_34", p = "_listItemText_11hhc_41", B = "_learnMore_11hhc_46", t = {
  container: d,
  divider: h,
  title: m,
  list: R,
  listTitle: G,
  listItem: O,
  listItemText: p,
  learnMore: B
};
function C(r) {
  const {
    isHourly: I,
    onClick: l
  } = r, [s, E] = N(!0), o = c(I ? _("SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_SUPPORT_ARTICLE_LINK_W2") : _("SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_SUPPORT_ARTICLE_LINK_1099"));
  return /* @__PURE__ */ e("div", {
    className: t.container,
    children: /* @__PURE__ */ e(T, {
      title: _("SWING_HERO_YOUR_STATUS"),
      isOpen: s,
      onToggle: () => E(!s),
      children: /* @__PURE__ */ i(a, {
        children: [/* @__PURE__ */ e("p", {
          className: t.title,
          children: _("SWING_HERO_SWING_SUB")
        }), /* @__PURE__ */ e("div", {
          className: t.divider
        }), /* @__PURE__ */ e("p", {
          children: _("SWING_HERO_INELIGIBLE_STATUS_SUB_TEXT")
        }), /* @__PURE__ */ e("div", {
          className: t.divider
        }), /* @__PURE__ */ i("div", {
          children: [/* @__PURE__ */ e("p", {
            className: t.listTitle,
            children: _("SWING_HERO_INELIGIBLE_LIST_TITLE")
          }), /* @__PURE__ */ i("ul", {
            className: t.list,
            children: [/* @__PURE__ */ e(n, {
              children: _("SWING_HERO_INELIGIBLE_LIST_FIRST_ITEM")
            }), /* @__PURE__ */ e(n, {
              children: _("SWING_HERO_INELIGIBLE_LIST_SECOND_ITEM")
            }), /* @__PURE__ */ i(n, {
              children: [_("SWING_HERO_INELIGIBLE_LIST_SKILL_BUILDERS_PREFIX"), " ", /* @__PURE__ */ e("a", {
                href: "#",
                style: {
                  textDecoration: "underline"
                },
                onClick: (L) => {
                  L.preventDefault(), l == null || l();
                },
                children: _("SWING_HERO_INELIGIBLE_LIST_SKILL_BUILDERS_BUTTON")
              }), " ", _("SWING_HERO_INELIGIBLE_LIST_SKILL_BUILDERS_SUFFIX")]
            })]
          })]
        }), /* @__PURE__ */ e("div", {
          className: t.divider
        }), /* @__PURE__ */ i("p", {
          children: [/* @__PURE__ */ e("a", {
            href: c("/hc/en-us/requests/new"),
            target: "_blank",
            rel: "noreferrer",
            children: _("SWING_HERO_INELIGIBLE_CONTACT_SUPPORT_START")
          }), " ", _("SWING_HERO_INELIGIBLE_CONTACT_SUPPORT_END")]
        }), /* @__PURE__ */ e("div", {
          className: t.divider
        }), /* @__PURE__ */ i("p", {
          className: t.learnMore,
          children: [`${_("SWING_HERO_LEARN_MORE_ABOUT")} `, /* @__PURE__ */ e("a", {
            href: o,
            target: "_blank",
            rel: "noreferrer",
            children: _("SWING_HEROES")
          }), "."]
        })]
      })
    })
  });
}
function n(r) {
  const {
    children: I
  } = r;
  return /* @__PURE__ */ i("li", {
    className: t.listItem,
    children: [/* @__PURE__ */ e(S, {
      name: "Check Circle"
    }), /* @__PURE__ */ e("span", {
      className: t.listItemText,
      children: I
    })]
  });
}
export {
  C as SwingHeroInEligible
};
