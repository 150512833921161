import { jsxs as r, jsx as l } from "react/jsx-runtime";
import { Colors as t } from "../../foundations/Colors/Colors.js";
import { I as i } from "../../../Icon-p8J22jb2.js";
import "../../../AuthProvider-ykImoPqe.js";
import "@ionic/react";
import s from "../../string-table.js";
import "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import '../../../css/AliasBanner.css';const a = {
  "alias-banner-container": "_alias-banner-container_lyg9t_1",
  "alias-banner-label": "_alias-banner-label_lyg9t_16",
  "alias-truncate": "_alias-truncate_lyg9t_22"
};
function g(n) {
  const {
    email: e
  } = n;
  return /* @__PURE__ */ r("div", {
    role: "complementary",
    className: a["alias-banner-container"],
    children: [/* @__PURE__ */ l(i, {
      name: "Eye Glasses",
      color: t.white200
    }), /* @__PURE__ */ r("span", {
      className: `${a["alias-banner-label"]} ${a["alias-truncate"]}`,
      children: [s("LABEL_ALIASED"), ": ", e]
    })]
  });
}
export {
  g as AliasBanner
};
