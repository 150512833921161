import { jsx as t, jsxs as n, Fragment as I } from "react/jsx-runtime";
import { I as y, g as S } from "../../../Icon-p8J22jb2.js";
import { IonBackdrop as R, IonButton as h, IonText as L } from "@ionic/react";
import { useState as s, useLayoutEffect as B } from "react";
import { Colors as C } from "../../foundations/Colors/Colors.js";
import { u as k, i as M, E as D, c as P } from "../../../AuthProvider-ykImoPqe.js";
import l from "../../string-table.js";
import "../../../react-paginate-CpOsCkIq.js";
import { LoadingInterstitial as V } from "../LoadingInterstitial/LoadingInterstitial.js";
import '../../../css/Login.css';const Y = "_form_wmum0_1", W = "_divider_wmum0_5", j = "_emailInput_wmum0_27", z = "_loadingContainer_wmum0_31", F = "_loginButton_wmum0_48", H = "_buttonText_wmum0_56", U = "_googleIcon_wmum0_61", e = {
  form: Y,
  divider: W,
  emailInput: j,
  loadingContainer: z,
  loginButton: F,
  buttonText: H,
  googleIcon: U
};
function io(i) {
  const {
    loginWithPasswordPath: r,
    history: N,
    onLoginEmailSubmit: b,
    buildProfile: d,
    apiError: c
  } = i, {
    signIn: _,
    isAuthenticating: w
  } = k(), [m, g] = s(""), [O, a] = s(), [G, f] = s(!1), [E, p] = s(c);
  B(() => {
    p(c);
  }, [c]);
  const v = (o) => {
    g(o), J(o) ? f(!0) : f(!1);
  }, x = async () => {
    const o = await b(m).catch((u) => a(u));
    if (o)
      switch (o) {
        case "COGNITO":
          a(void 0), N.push({
            pathname: r,
            // Explicitly not using `useLocation` as we want to live value vs
            // cached value.
            search: window.location.search,
            state: {
              email: m
            }
          });
          break;
        case "GOOGLE":
          a(void 0), _({
            provider: o.toLowerCase()
          }).catch((u) => {
            a(u);
          });
          break;
      }
  };
  async function A(o) {
    o.preventDefault(), p(void 0), await x();
  }
  async function T(o) {
    o.preventDefault(), a(void 0), g(""), await _({
      provider: "google"
    });
  }
  return /* @__PURE__ */ t(M, {
    title: l("LOGIN_EMAIL_ENTRY_LOGIN_MESSAGE"),
    children: w ? /* @__PURE__ */ n(I, {
      children: [/* @__PURE__ */ t(R, {
        visible: !0
      }), /* @__PURE__ */ t("div", {
        className: e.loadingContainer,
        children: /* @__PURE__ */ t(V, {})
      })]
    }) : /* @__PURE__ */ n(I, {
      children: [/* @__PURE__ */ n("form", {
        className: e.form,
        onSubmit: async (o) => await A(o),
        children: [/* @__PURE__ */ t("div", {
          className: e.emailInput,
          children: /* @__PURE__ */ t(D, {
            email: m,
            onEmailInput: (o) => v(o),
            readOnly: !1,
            error: O,
            provider: "cognito",
            buildProfile: d
          })
        }), /* @__PURE__ */ n(h, {
          type: "submit",
          size: "default",
          expand: "block",
          className: e.loginButton,
          disabled: !G,
          children: [/* @__PURE__ */ t(L, {
            className: e.buttonText,
            children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_ENTER_PASSWORD")
          }), /* @__PURE__ */ t(y, {
            name: "Arrow Right",
            color: C.white200
          })]
        })]
      }), /* @__PURE__ */ t(q, {
        label: l("LOGIN_EMAIL_ENTRY_LABEL_OR")
      }), /* @__PURE__ */ n("form", {
        className: e.form,
        onSubmit: async (o) => await T(o),
        children: [/* @__PURE__ */ n(h, {
          type: "submit",
          size: "default",
          fill: "outline",
          expand: "block",
          className: e.loginButton,
          children: [/* @__PURE__ */ t("img", {
            src: S,
            className: e.googleIcon,
            alt: "Login with Google"
          }), /* @__PURE__ */ t(L, {
            className: e.buttonText,
            children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_CONTINUE_WITH_GOOGLE")
          })]
        }), E && /* @__PURE__ */ t(P, {
          buildProfile: d,
          error: E
        })]
      })]
    })
  });
}
function q(i) {
  const {
    label: r
  } = i;
  return /* @__PURE__ */ t("div", {
    className: e.divider,
    children: r
  });
}
function J(i) {
  return new RegExp(/.+@.+\..{2,}/).test(i);
}
export {
  io as Login,
  J as isValidEmail
};
