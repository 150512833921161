// Logos

export const swingLogo = require("./logos/swing-logo-200.png");
export const swingLogomarkBlue = require("./logos/swing-logomark-blue-32.png");
export const googleSignInLogo2x = require("./logos/google_sign_in.svg");

// SVG Logos

// You have to add the "default" declaration for SVG's. Webpack problems.
// https://stackoverflow.com/questions/59070216/webpack-file-loader-outputs-object-module

export const swingLogomarkWhite = require("./logos/swing-logomark-white-32.svg");
export const swingLogo130 = require("./logos/swing-logo-130.svg");

// SVG Icons

// Note: .default does not work for Vite and is not needed
export const twoCalendarsIconSVG = require("./icons/two-calendars.svg");
export const appleIconSVG = require("./icons/apple.svg"); //this is the new apple icon, not Apple the company
export const schoolIconSVG = require("./icons/school.svg");
export const teacherIconSVG = require("./icons/teacher.svg");
export const googleIconSVG = require("./logos/google_sign_in.svg");
export const calendarOverlapSVG = require("./icons/calendar-overlap.svg");
export const calendarSVG = require("./icons/calendar.svg");
export const crownSVG = require("./icons/crown.svg");
export const googleSVG = require("./icons/google.svg");
export const starCircleSVG = require("./icons/star-circle.svg");
export const swingHeroSVG = require("./icons/swing-hero.svg");
export const lightBulbSVG = require("./icons/light-bulb.svg");
export const ukgSVG = require("./icons/ukg.svg");

// PNG placeholder images
export const subPhotoPNG = require("./images/sub-photo.png");
export const subPhotoPlaceholderPNG = require("./images/sub-photo-placeholder.png");

// PNG images
export const appStoreBadge = require("./images/app-store-badge.png");
export const googlePlayBadge = require("./images/google-play-badge.png");

// Login Image
export const loginPNG = require("./images/login-stock.png");
