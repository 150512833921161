import { ActionSheet } from "@capacitor/action-sheet";
import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { IonText } from "@ionic/react";
import { DateTime } from "luxon";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { Colors, DataItemView, Icon, IconName } from "swing-components";

import {
  CardHeader,
  IntervalWithTimeZone,
  ModalCancelPolicy,
  ModalDatesTimes,
  ModalPayBreakdown,
  ModalSchoolFeedbackSubmitted,
  useModal,
} from "~components";
import {
  formatCityStateZip,
  formatPay,
  formatStringArray,
  generateGoogleMapsLink,
  handleMapLinkNavigation,
  msg,
  openLinkInApp,
  RequestData,
  useIsDesktop,
} from "~utils";
import styles from "./RequestDetailCard.module.css";

type RequestDetailCardProps = {
  request: RequestData;
  alertBox?: JSX.Element;
};

export function RequestDetailCard(props: RequestDetailCardProps) {
  const { request, alertBox } = props;
  // Note: we have some inconsistencies between mobile and desktop that
  // are more suitable to be solved in JS to avoid rendering the DOM Nodes
  // in addition to conditionally hooking into the sub-component props
  const isDesktop = useIsDesktop();

  const { openModal: openPayModal, closeModal: closePayModal } = useModal({
    component: (
      <ModalPayBreakdown
        payment={request.payment}
        isMultiWeek={request.isMultiWeek}
        isMultiDay={request.isMultiDay}
        onDismiss={() => closePayModal()}
      />
    ),
    modalOptions: {
      cssClass: `pay-breakdown-modal`,
    },
  });

  const { openModal: openCancelPolicyModal, closeModal: closeCancelPolicyModal } = useModal({
    component: <ModalCancelPolicy onDismiss={() => closeCancelPolicyModal()} />,
    modalOptions: {
      cssClass: `cancel-policy-modal`,
    },
  });

  const intervalsWithTimezone: IntervalWithTimeZone[] = request.intervals.map((interval) => {
    return {
      startDate: DateTime.fromISO(interval.startDate).setZone(request.school.timeZone),
      endDate: DateTime.fromISO(interval.endDate).setZone(request.school.timeZone),
    };
  });

  const schoolAddress = {
    street: request?.school?.address?.street ?? "",
    city: request?.school?.address?.city ?? "",
    state: request?.school?.address?.state ?? "",
    zip: request?.school?.address?.zip ?? "",
  };

  const googleMapsLink = generateGoogleMapsLink(schoolAddress);

  const { openModal: openDateTimesModal, closeModal: closeDateTimesModal } = useModal({
    component: (
      <ModalDatesTimes
        intervals={intervalsWithTimezone}
        message={msg("LABEL_TIMEZONE_DISCLAIMER", {
          schoolName: request.school.name,
          timeZone: request.school.timeZone,
        })}
        onDismiss={() => closeDateTimesModal()}
      />
    ),
    modalOptions: {
      cssClass: "dates-times-modal",
    },
  });

  const { openModal: openReviewedFeedbackModal, closeModal: closeReviewedFeedbackModal } = useModal(
    {
      component: (
        <ModalSchoolFeedbackSubmitted
          onDismiss={() => closeReviewedFeedbackModal()}
          feedback={request.schoolFeedback}
        />
      ),
      modalOptions: {
        cssClass: "modal-sub-profile-edit",
      },
    },
  );

  return (
    <div style={{ height: "fit-content", backgroundColor: Colors.white200 }}>
      <CardHeader
        requestId={request.id}
        schoolName={request.school.name}
        schoolId={request.school.id}
        status={request.status}
      />
      {alertBox}
      <div className={styles.divider}></div>
      <div style={{ padding: "0px 16px 16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          {!isDesktop ? (
            <div className={styles["date-row-wrapper"]}>
              <div
                className={styles["date-time-mobile"]}
                onClick={request.isMultiDay ? openDateTimesModal : undefined}
              >
                <div style={{ display: "grid" }}>
                  <IonText className={styles["date-time-mobile-label"]}>
                    {msg("LABEL_START")}:
                  </IonText>
                  <IonText>
                    {request.displayDate
                      ? request.isMultiDay
                        ? request.displayDate?.split(" - ")[0]
                        : request.displayDate
                      : msg("UNPROVIDED_DATA_MESSAGE")}
                  </IonText>
                </div>
                {request.isMultiDay ? (
                  <div className={styles["date-row"]}>
                    <div style={{ display: "grid" }}>
                      <IonText className={styles["date-time-mobile-label"]}>
                        {msg("LABEL_END")}:
                      </IonText>
                      <IonText>
                        {request.displayDate
                          ? request.displayDate?.split(" - ")[1]
                          : msg("UNPROVIDED_DATA_MESSAGE")}
                      </IonText>
                    </div>
                    <div>
                      <Icon name="Chevron Right" />
                    </div>
                  </div>
                ) : (
                  <div style={{ display: "grid" }}>
                    <IonText className={styles["date-time-mobile-label"]}>
                      {msg("LABEL_TIME")}:
                    </IonText>
                    <IonText>
                      {request.displayTime ? request.displayTime : msg("UNPROVIDED_DATA_MESSAGE")}
                    </IonText>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <DataItemView label={msg("LABEL_DATE")} icon="Calendar Overlap">
                <div>
                  <IonText>{request.displayDate}</IonText>
                </div>
                {request.isMultiDay ? (
                  <IonText
                    style={{ color: Colors.blue500, cursor: "pointer" }}
                    onClick={openDateTimesModal}
                  >
                    {msg("DATES_TIMES_LABEL")}
                  </IonText>
                ) : (
                  <IonText>{request.displayTime}</IonText>
                )}
              </DataItemView>
            </>
          )}
          {!isDesktop && <div className={styles["divider-mobile"]}></div>}
          {request.payment && (
            <DataItemView label={isDesktop ? msg("LABEL_PAY") : ""} icon="Monetization">
              <div className={styles["pay-row"]} onClick={!isDesktop ? openPayModal : undefined}>
                <IonText>
                  {request.payment?.payValue
                    ? formatPay(request.payment.isHourly, request.payment.payValue)
                    : msg("UNPROVIDED_DATA_MESSAGE")}
                </IonText>
                {!isDesktop && <Icon name="Chevron Right" />}
              </div>
              {isDesktop && (
                <IonText
                  style={{ color: Colors.blue500, cursor: "pointer" }}
                  onClick={openPayModal}
                >
                  {msg("PAY_BREAKDOWN_LABEL")}
                </IonText>
              )}
            </DataItemView>
          )}
          {request.payment && !isDesktop && <div className={styles["divider-mobile"]}></div>}
          {FEATURE_FLAGS.SCHOOL_FEEDBACK && request.hasFeedback && (
            <>
              <DataItemView
                label={isDesktop ? msg("SCHOOL_FEEDBACK_REVIEWED_LABEL") : ""}
                icon="Check Circle"
                iconColor={Colors.green500}
              >
                {isDesktop ? (
                  <IonText
                    style={{ color: Colors.blue500, cursor: "pointer" }}
                    onClick={openReviewedFeedbackModal}
                  >
                    {msg("SCHOOL_FEEDBACK_VIEW_REVIEW_LABEL")}
                  </IonText>
                ) : (
                  <div className={styles["pay-row"]} onClick={openReviewedFeedbackModal}>
                    <span>{msg("SCHOOL_FEEDBACK_REVIEWED_LABEL")}</span>
                    <Icon name="Chevron Right" />{" "}
                  </div>
                )}
              </DataItemView>
              {!isDesktop && <div className={styles["divider-mobile"]}></div>}
            </>
          )}
          <DataItemView label={isDesktop ? msg("LABEL_ADDRESS") : ""} icon="Location">
            {request.school.address ? (
              <a
                style={{ display: "flex", flexDirection: "column" }}
                target="_blank"
                rel="noreferrer"
                href={googleMapsLink}
                onClick={(e) =>
                  handleMapLinkNavigation(e, {
                    getPlatform: Capacitor.getPlatform,
                    appLauncher: AppLauncher,
                    actionSheet: ActionSheet,
                    openLinkInApp: openLinkInApp,
                    query: encodeURIComponent(
                      `${schoolAddress.street} ${schoolAddress.city}, ${schoolAddress.state}, ${schoolAddress.zip}`,
                    ),
                    link: googleMapsLink,
                  })
                }
              >
                {request.school.address.street && (
                  <IonText className={styles["truncate-one-line"]}>
                    {request.school.address.street}
                  </IonText>
                )}
                <IonText className={styles["truncate-one-line"]}>
                  {formatCityStateZip(request.school.address)}
                </IonText>
              </a>
            ) : (
              <IonText>{msg("UNPROVIDED_DATA_MESSAGE")}</IonText>
            )}
          </DataItemView>
          <DataItemView label={msg("LABEL_CONTACT")} icon="Phone">
            {request.contact ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <IonText>
                  {request.contact.name} -{" "}
                  <a href={`tel:${request.contact.phoneNumber}`}>{request.contact.phoneNumber}</a>
                </IonText>
                <IonText>
                  <a href={`mailto:${request.contact.email}`}>{request.contact.email}</a>
                </IonText>
              </div>
            ) : (
              <IonText>{msg("UNPROVIDED_DATA_MESSAGE")}</IonText>
            )}
          </DataItemView>
          <DataItemView label={msg("LABEL_GRADE")} icon="Grad Cap">
            <IonText>
              {request.grades ? formatStringArray(request.grades) : msg("UNPROVIDED_DATA_MESSAGE")}
            </IonText>
          </DataItemView>
          <DataItemView label={msg("LABEL_SUBJECT")} icon="Book">
            <IonText>
              {request.subjects
                ? formatStringArray(request.subjects)
                : msg("UNPROVIDED_DATA_MESSAGE")}
            </IonText>
          </DataItemView>
          <SimpleDataDisplay label={msg("LABEL_TEACHER")} icon="Teacher" data={request.teacher} />
          <DataItemView label={msg("LABEL_LESSON_PLAN")} icon="Clipboard">
            <IonText>
              {request.isLessonPlanProvided
                ? msg("LESSON_PLAN_PROVIDED")
                : msg("UNPROVIDED_DATA_MESSAGE")}
            </IonText>
          </DataItemView>
          <SimpleDataDisplay label={msg("LABEL_NOTES")} icon="Note" data={request.memo} />
          <CancelPolicyButton onClick={openCancelPolicyModal} />
        </div>
      </div>
    </div>
  );
}

type SimpleDisplayDataProps = {
  label: string;
  icon: IconName;
  data: string | number | undefined;
};

function SimpleDataDisplay(props: SimpleDisplayDataProps) {
  const { label, icon, data } = props;

  return (
    <DataItemView label={label} icon={icon}>
      <IonText>{data ? `${data}` : msg("UNPROVIDED_DATA_MESSAGE")}</IonText>
    </DataItemView>
  );
}
type CancelPolicyButtonProps = { onClick: () => void };

export function CancelPolicyButton(props: CancelPolicyButtonProps) {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        backgroundColor: Colors.white300,
        border: "solid",
        borderColor: Colors.slate300,
        borderRadius: 4,
        borderWidth: 1,
        padding: 8,
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon name="Block" />
        <div style={{ display: "flex", flexDirection: "column", marginLeft: 8 }}>
          <IonText className={styles["label"]}>{msg("CANCEL_POLICY_LABEL")}</IonText>
          <IonText>{msg("CANCEL_POLICY_BUTTON_SUBHEADING")}</IonText>
        </div>
      </div>
      <Icon name="Chevron Right" />
    </div>
  );
}
