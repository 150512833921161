import { jsx as t } from "react/jsx-runtime";
import o from "../../../../string-table.js";
import "@ionic/react";
import "../../../../../AuthProvider-ykImoPqe.js";
import "react";
import "../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { Section as e } from "../Section/Section.js";
function O(r) {
  const {
    about: n,
    editable: i
  } = r;
  return /* @__PURE__ */ t(e, {
    title: o("SUB_PROFILE_ABOUT_HEADER"),
    onEditClick: i != null && i.isEditable ? i.onEditAboutMe : void 0,
    children: /* @__PURE__ */ t("div", {
      children: /* @__PURE__ */ t("p", {
        style: {
          marginBottom: 0,
          lineHeight: "var(--swing-leading-5)"
        },
        children: n ? /* @__PURE__ */ t("span", {
          children: n
        }) : /* @__PURE__ */ t("span", {
          style: {
            fontStyle: "italic"
          },
          children: i != null && i.isEditable ? `${o("SUB_PROFILE_ABOUT_EDIT_EMPTY")}.` : o("SUB_PROFILE_NO_INFORMATION")
        })
      })
    })
  });
}
export {
  O as AboutMe
};
