import { Capacitor } from "@capacitor/core";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export function usePullToRefresh(elementRef: RefObject<HTMLDivElement>, onRefresh: () => void) {
  const REFRESH_TRIGGER_THRESHOLD = 110;
  const MAX_PULL_DISTANCE = 180;
  const DAMPING_FACTOR = 0.4;

  const touchStartYRef = useRef(0);
  const [isRefreshIndicatorVisible, setIsRefreshIndicatorVisible] = useState(false);
  const calculatePullDistance = useCallback((distance: number) => {
    // Damps the pull distance for a smooth, elastic effect.
    return MAX_PULL_DISTANCE * (1 - Math.exp((-DAMPING_FACTOR * distance) / MAX_PULL_DISTANCE));
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") return;

    const refreshContent = elementRef.current;
    if (!refreshContent) return;

    const onTouchStart = (startEvent: TouchEvent) => {
      touchStartYRef.current = startEvent.touches[0].clientY;
    };

    const onTouchMove = (moveEvent: TouchEvent) => {
      if (!refreshContent) return;

      const currentY = moveEvent.touches[0].clientY;
      let pullDistance = currentY - touchStartYRef.current;
      if (pullDistance < 0) return;

      pullDistance = Math.min(pullDistance, MAX_PULL_DISTANCE);

      refreshContent.style.transform = `translateY(${calculatePullDistance(pullDistance)}px)`;
      setIsRefreshIndicatorVisible(pullDistance > REFRESH_TRIGGER_THRESHOLD);
    };

    const onTouchEnd = (endEvent: TouchEvent) => {
      if (!refreshContent) return;

      // return the refreshContent to its initial position
      refreshContent.style.transform = "translateY(0)";
      setIsRefreshIndicatorVisible(false);

      // add a transition while it's going back up
      refreshContent.style.transition = "transform 0.2s";

      // If the user pulled far enough for the loading indicator to appear
      // we will trigger the refresh
      const totalPullDistance = endEvent.changedTouches[0].clientY - touchStartYRef.current;
      if (totalPullDistance > REFRESH_TRIGGER_THRESHOLD) {
        onRefresh();
      }

      refreshContent.addEventListener("transitionend", onTransitionEnd, { once: true });
    };

    const onTransitionEnd = () => {
      if (!refreshContent) return;
      refreshContent.style.transition = "";
      refreshContent.removeEventListener("transitionend", onTransitionEnd);
    };

    refreshContent.addEventListener("touchstart", onTouchStart, { passive: true });
    refreshContent.addEventListener("touchmove", onTouchMove, { passive: true });
    refreshContent.addEventListener("touchend", onTouchEnd);

    return () => {
      refreshContent.removeEventListener("touchstart", onTouchStart);
      refreshContent.removeEventListener("touchmove", onTouchMove);
      refreshContent.removeEventListener("touchend", onTouchEnd);
    };
  }, [elementRef, calculatePullDistance, onRefresh]);

  return { isRefreshIndicatorVisible };
}
