import { CSSProperties } from "react";
import { ChoiceButton } from "swing-components";

import type { ChoiceAnswerType } from "~onboarding/utils";

type ChoiceAnswerProps = Omit<ChoiceAnswerType, "type"> & {
  /**
   * The value of the answer. This is undefined when there is no answer.
   */
  value?: string;
  /**
   * When answering the Question, pass the `value` to the parent.
   */
  onChange: (value: string) => void;
  /**
   * Disables the editability of the answer.
   */
  isReadOnly?: boolean;
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    columnGap: 8,
  } as CSSProperties,
};

export function ChoiceAnswer(props: ChoiceAnswerProps) {
  const { value, onChange, isReadOnly, options } = props;

  return (
    <div style={styles.container}>
      {options.map((option) => (
        <div key={option.value} style={{ flex: 1 }}>
          <ChoiceButton
            icon={option.icon}
            isDisabled={isReadOnly}
            isSelected={value === option.value}
            onChange={onChange}
            label={option.label}
            value={option.value}
          />
        </div>
      ))}
    </div>
  );
}
