import { App, URLOpenListenerEvent } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { generateSwingSubsProdURL, generateSwingSubsStagingURL } from "swing-utils";

// Listen for appUrlOpen events to redirect to the correct page when a deep link is found
export function AppUrlListener() {
  const history = useHistory();

  const allowedHostnames = useMemo(() => {
    return [
      new URL(generateSwingSubsProdURL()).hostname,
      new URL(generateSwingSubsStagingURL()).hostname,
    ];
  }, []);

  useEffect(() => {
    let listener: PluginListenerHandle;

    const setupListener = async () => {
      listener = await App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        const url = new URL(event.url);
        if (allowedHostnames.includes(url.hostname)) {
          const params = new URLSearchParams(url.search);
          const destination = params.get("redirectTo") || url.pathname;

          if (destination) {
            history.push(destination);
          }
        }
      });
    };

    setupListener();

    return () => {
      listener?.remove();
    };
  }, [allowedHostnames, history]);

  return null;
}
