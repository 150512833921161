import { Capacitor } from "@capacitor/core";
import { focusManager, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AlertBox, Colors } from "swing-components";

import { ContentSingleColumn, OpeningsCard, useAuth } from "~components";
import { GET, msg, OpeningsData, PendingConfirmData } from "~utils";
import { createRequestUrl } from "../Routes";
import { ScorePage } from "../ScoreTemplates/ScorePage";
import { PullToRefreshLoadingIndicator } from "./PullToRefreshLoadingIndicator";

export function Requests() {
  const {
    data: openings,
    isLoading: isOpeningsLoading,
    // isSuccess: isOpeningsSuccess,
    refetch: refetchOpenings,
  } = useQuery({
    queryKey: ["fetchOpenings"],
    queryFn: () => GET("/api/sub/request/openings"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
  });

  const {
    data: confirmRequests,
    isLoading: isConfirmPendingLoading,
    // isSuccess: isConfirmPendingSuccess,
    refetch: refetchConfirmRequests,
  } = useQuery({
    queryKey: ["fetchPendingConfirm"],
    queryFn: () => GET("/api/sub/request/pending-confirm"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
  });

  const [lastUpdatedTime, setLastUpdatedTime] = useState(DateTime.now());
  // Update the lastUpdatedTime when the app regains focus.
  // refetchOnWindowFocus is triggered automatically on web and mobile devices,
  // but we also need to update the time to make sure it's in sync.
  // If an error is thrown during the fetch, the timestamp will update,
  // but the UI will not show the time as it will be covered by the error <AlertBox/>
  useEffect(() => {
    const unsubscribe = focusManager.subscribe(async (isFocused) => {
      if (!isFocused) return;

      setLastUpdatedTime(DateTime.now());
      // If UI requirements change, and the timestamp should only update on successful data fetch:
      // Uncomment the following logic to manually refetch and update the time
      // only when the requests succeed. At this time, we will need to consider whether
      // refetchOnWindowFocus should be set to false.

      // const [openingsResult, confirmResult] = await Promise.all([
      //   refetchOpenings(),
      //   refetchConfirmRequests(),
      // ]);

      // if (openingsResult.status === "success" || confirmResult.status === "success") {
      //   setLastUpdatedTime(DateTime.now());
      // }
    });
    return () => unsubscribe();
  }, []);

  return (
    <ScorePage
      title={msg("PAGE_TITLE_OPENINGS")}
      isLoading={isOpeningsLoading || isConfirmPendingLoading}
    >
      <ContentSingleColumn>
        {/* TODO update with conditional for no data state when updating openings page */}
        {openings?.data && (
          <RequestsView
            requests={openings.data}
            confirmRequests={confirmRequests?.data}
            lastUpdatedTime={lastUpdatedTime}
            onRefresh={() => {
              setLastUpdatedTime(DateTime.now());
              refetchOpenings();
              refetchConfirmRequests();
            }}
          />
        )}
      </ContentSingleColumn>
    </ScorePage>
  );
}

export type RequestsViewProps = {
  requests: OpeningsData[];
  confirmRequests?: PendingConfirmData[];
  lastUpdatedTime: DateTime;
  onRefresh: () => void;
};

export function RequestsView(props: RequestsViewProps) {
  const { requests = [], confirmRequests, onRefresh, lastUpdatedTime } = props;
  const {
    userInfo: { isPhoneNumberVerified, notificationsSettings },
  } = useAuth();
  const [timeSinceUpdate, setTimeSinceUpdate] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = DateTime.now();
      setTimeSinceUpdate(Math.floor(now.diff(lastUpdatedTime, "minutes").minutes));
    }, 60 * 1000); // Update every minute

    // Initial update
    setTimeSinceUpdate(Math.floor(DateTime.now().diff(lastUpdatedTime, "minutes").minutes));

    return () => clearInterval(intervalId);
  }, [lastUpdatedTime]);

  const showPhoneVerificationAlert = isPhoneNumberVerified === false && requests.length > 0;
  const showEnableNotificationsAlert =
    !(notificationsSettings?.push || notificationsSettings?.sms) && requests.length > 0;

  const refreshContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {Capacitor.getPlatform() !== "web" && (
        <PullToRefreshLoadingIndicator elementRef={refreshContainerRef} onRefresh={onRefresh} />
      )}
      <div
        ref={refreshContainerRef}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "calc(100% - 48px)",
            maxWidth: "900px",
          }}
        >
          <div style={{ margin: "0 auto" }}>
            {showEnableNotificationsAlert && <EnableNotificationsAlert />}
            {showPhoneVerificationAlert && <PhoneVerificationAlert />}
            {confirmRequests && <ConfirmRequests confirmRequests={confirmRequests} />}
            <p style={{ margin: 16, color: Colors.slate600, fontSize: 12, fontStyle: "italic" }}>
              {msg("LABEL_UPDATED", { min: timeSinceUpdate })}
            </p>
            {requests.length ? (
              <div style={{ paddingBottom: "16px" }}>
                {requests.map((req) => (
                  <div key={req.id} style={{ margin: "16px 16px 24px 16px" }}>
                    <OpeningsCard request={req} />
                  </div>
                ))}
                <span style={{ display: "flex", justifyContent: "center", fontStyle: "italic" }}>
                  {msg("LABEL_END_OF_PAGE")}!
                </span>
              </div>
            ) : (
              <OpeningsEmptyView />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export function OpeningsEmptyView() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "var(--spacing-lg)",
        width: "100%",
        maxWidth: "440px",
        margin: "0 auto",
        // padding bottom to account for when confirm alert box is shown also
        padding: "104px 16px 32px 16px",
        textAlign: "center",
      }}
    >
      <img
        src="assets/man-with-large-magnifying-glass.svg"
        alt={msg("SETUP_ILLUSTRATION_IMAGE_ALT")}
        style={{ maxWidth: "200px" }}
      />
      <span style={{ fontSize: "var(--swing-font-md)", fontWeight: "var(--swing-font-semibold)" }}>
        {msg("OPENINGS_NONE_AVAILABLE")}
      </span>
      <span style={{ fontSize: "var(--swing-font-md)" }}>{msg("OPENINGS_RETURN_LATER")}.</span>
    </div>
  );
}

type ConfirmRequestsProps = {
  confirmRequests: PendingConfirmData[];
};

export function ConfirmRequests(props: ConfirmRequestsProps) {
  const { confirmRequests } = props;

  return confirmRequests.map((request) => {
    const formattedStartDate = DateTime.fromISO(request.startDate).toFormat("LLL dd");
    const formattedEndDate = DateTime.fromISO(request.endDate).toFormat("LLL dd");
    const formattedExpirationDate = DateTime.fromISO(request.confirmExpirationDate).toFormat(
      "LLL, dd",
    );
    const formattedExpirationTime = DateTime.fromISO(request.confirmExpirationDate)
      .toFormat("t")
      .toLowerCase();

    return (
      <PendingConfirmationBanner
        key={request.id}
        endDate={formattedEndDate}
        expirationDate={formattedExpirationDate}
        expirationTime={formattedExpirationTime}
        schoolName={request.schoolName}
        startDate={formattedStartDate}
        url={createRequestUrl(request.id)}
      />
    );
  });
}

type PendingConfirmationBannerProps = {
  endDate: string;
  expirationDate: string;
  expirationTime: string;
  schoolName: string;
  startDate: string;
  url: string;
};

function PendingConfirmationBanner(props: PendingConfirmationBannerProps) {
  const { endDate, expirationDate, expirationTime, schoolName, startDate, url } = props;
  return (
    <div
      style={{
        margin: "16px",
      }}
    >
      <AlertBox showIcon color="success" title={msg("CONFIRM_REQUEST_TITLE")}>
        {msg("CONFIRM_REQUEST_MESSAGE_1", {
          schoolName,
          startDate,
          endDate,
        })}
        {", "}
        <Link to={url}>{msg("CONFIRM_REQUEST_MESSAGE_LINK_LABEL")}</Link>{" "}
        {msg("CONFIRM_REQUEST_MESSAGE_2")}{" "}
        <span style={{ fontWeight: "var(--swing-font-bold)" }}>{expirationDate}</span>{" "}
        {msg("CONFIRM_REQUEST_MESSAGE_3")}{" "}
        <span style={{ fontWeight: "var(--swing-font-bold)" }}>{expirationTime}</span>.
      </AlertBox>
    </div>
  );
}

export function PhoneVerificationAlert() {
  return (
    <div style={{ margin: "16px" }}>
      <AlertBox color="warning" title={msg("PHONE_VERIFICATION_ALERT_TITLE")} showIcon>
        <Link to="/profile">{msg("PHONE_VERIFICATION_ALERT_MSG_LINK_LABEL")}</Link>
        {` ${msg("PHONE_VERIFICATION_ALERT_MSG")}`}
      </AlertBox>
    </div>
  );
}

export function EnableNotificationsAlert() {
  // Check and remove this item if it exists now that
  // we would like to always display the alert if
  // notifications is turned off.
  useEffect(() => {
    if (localStorage.getItem("notificationsAlertDismissed")) {
      localStorage.removeItem("notificationsAlertDismissed");
    }
  }, []);

  return (
    <div style={{ margin: "16px" }}>
      <AlertBox color="warning" title={msg("NEVER_MISS_AN_OPPORTUNITY_ALERT_TITLE")} showIcon>
        <Link to="/more/notifications">{msg("NOTIFICATIONS_ALERT_MSG_LINK_LABEL")}</Link>
        {` ${msg("NOTIFICATIONS_ALERT_MSG")}`}
      </AlertBox>
    </div>
  );
}
