/*
 * NOTE:
 * If you are testing this component locally, make sure that the legacy app is running locally as well.
 * The legacy app must be accessible at the URL specified in LEGACY_URLS for the current BUILD_PROFILE.
 */
import { Capacitor } from "@capacitor/core";

import { ContentSingleColumn } from "~components";
import { LEGACY_URLS } from "~pages";
import { BUILD_PROFILE, msg } from "~utils";
import { ScorePage } from "../../ScoreTemplates";

export function MyDocuments() {
  if (Capacitor.getPlatform() === "web") {
    return (
      <ScorePage title={msg("SETTINGS_MY_DOCUMENTS")} hasBack>
        <ContentSingleColumn>
          <iframe
            title={msg("SETTINGS_MY_DOCUMENTS")}
            src={`${LEGACY_URLS[BUILD_PROFILE]}/#/neo/my-documents`}
            height="1000"
            style={{ width: "100%", border: "none", padding: 0 }}
          />
        </ContentSingleColumn>
      </ScorePage>
    );
  }
  return (
    <ScorePage title={msg("SETTINGS_MY_DOCUMENTS")} hasBack>
      <ContentSingleColumn>
        <div style={{ padding: "16px" }}>
          Documents are coming soon to the new app! For now, you can view and update your documents
          on the{" "}
          <a
            href={`${LEGACY_URLS[BUILD_PROFILE]}/#/neo/my-documents`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            web
          </a>
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}
