import { IonPage, IonRouterOutlet } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { useRef } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router";
import {
  ErrorResponseWithDataMessage,
  ForgotPassword,
  Login,
  LoginWithPassword,
} from "swing-components";

import { useAuth } from "~components";
import { BUILD_PROFILE, GET } from "~utils";

/********** Constants ********/
export const UNAUTHENTICATED_ROUTES = {
  login: "/",
  loginWithPassword: "/login",
  forgotPassword: "/forgot-password",
} as const;

/********** Component ***********/
/**
 * Entry point when not authenticated.
 */
export function UnAuthedRoutes() {
  /***** Hooks *****/
  const history = useHistory();
  const location = useLocation();
  const { apiError } = useAuth();

  // useRef used vs. useState as it avoids forcing re-render / retry to pick
  // up the dynamic user email value that is passed into the query
  const userEmail = useRef("");
  const { refetch } = useQuery({
    queryKey: ["userProvider", userEmail.current],
    queryFn: () =>
      GET("/api/user/provider", { queryParams: { email: userEmail.current, "user-type": "SUB" } }),
    enabled: false,
    // turn off retry
    retry() {
      return false;
    },
  });

  async function handleOnLoginEmailSubmit(email: string) {
    userEmail.current = email;
    // throw so child components implementing catch can handle
    const { data } = await refetch({ throwOnError: true });
    return data?.provider;
  }

  function handleEmailEdit(email: string) {
    history.push({
      pathname: "/",
      state: { email },
    });
  }

  /***** Render *****/
  return (
    <IonPage>
      <IonRouterOutlet>
        {/*
          using children approach based on 5.1 + version of react router
          https://reactrouter.com/en/main/upgrading/v5#upgrade-to-react-router-v51
        */}
        <Route exact path={UNAUTHENTICATED_ROUTES.login}>
          <Login
            loginWithPasswordPath={UNAUTHENTICATED_ROUTES.loginWithPassword}
            history={history}
            onLoginEmailSubmit={handleOnLoginEmailSubmit}
            buildProfile={BUILD_PROFILE}
            apiError={apiError as AxiosError<ErrorResponseWithDataMessage>}
          />
        </Route>
        <Route exact path={UNAUTHENTICATED_ROUTES.loginWithPassword}>
          <LoginWithPassword
            forgotPasswordPath={UNAUTHENTICATED_ROUTES.forgotPassword}
            history={history}
            location={location}
            onEmailEdit={handleEmailEdit}
          />
        </Route>
        <Route exact path={UNAUTHENTICATED_ROUTES.forgotPassword}>
          <ForgotPassword
            loginWithPasswordPath={UNAUTHENTICATED_ROUTES.loginWithPassword}
            history={history}
            location={location}
          />
        </Route>
        {/* FALLBACK */}
        <Redirect
          // When hitting the unauthenticated route with a route other than the
          // ones listed above, save the pathname as a search param for re-routing
          // after login.
          to={(() => {
            // Keep the original search params
            const searchParams = new URLSearchParams(location.search);
            // Add the redirectTo search param
            searchParams.set("redirectTo", location.pathname);

            return {
              pathname: UNAUTHENTICATED_ROUTES["login"],
              search: `?${searchParams.toString()}`,
            };
          })()}
        />
      </IonRouterOutlet>
    </IonPage>
  );
}
