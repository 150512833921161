import { Capacitor } from "@capacitor/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { App } from "src/App";
import singletonStorage from "src/stores/store";
import { useAuth as _useAuth, AuthProvider } from "swing-components";

import {
  api,
  AUTHENTICATION_COOKIE_DOMAIN,
  COGNITO_APP_CLIENT_ID,
  COGNITO_USER_POOL_ID,
  FIREBASE_AUTH_CONFIG_API_KEY,
  FIREBASE_AUTH_CONFIG_APP_ID,
  FIREBASE_AUTH_CONFIG_AUTH_DOMAIN,
  FIREBASE_AUTH_CONFIG_MESSAGING_SENDER_ID,
  FIREBASE_AUTH_CONFIG_PROJECT_ID,
  FIREBASE_AUTH_CONFIG_STORAGE_BUCKET,
  GET,
  POST,
} from "~utils";

/********* Constants ********/
export const QUERY_CLIENT = new QueryClient();

export const STORAGE_AUTH_KEY = "auth";

/********* User APIs *********/
const userApi = async () => await GET("/api/sub/me");
const userSyncApi = async () => await POST("/api/user/me");
// COGNITO USER POOL INITIALIZATION
const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_APP_CLIENT_ID,
  // defaults to localStorage but we want our own storage implementation
  Storage: singletonStorage,
});

/********* Component *********/
export function AuthWrapper() {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      <AuthProvider
        api={api}
        authCookieDomain={AUTHENTICATION_COOKIE_DOMAIN}
        firebaseConfig={{
          apiKey: FIREBASE_AUTH_CONFIG_API_KEY,
          appId: FIREBASE_AUTH_CONFIG_APP_ID,
          authDomain: FIREBASE_AUTH_CONFIG_AUTH_DOMAIN,
          messagingSenderId: FIREBASE_AUTH_CONFIG_MESSAGING_SENDER_ID,
          projectId: FIREBASE_AUTH_CONFIG_PROJECT_ID,
          storageBucket: FIREBASE_AUTH_CONFIG_STORAGE_BUCKET,
        }}
        isWeb={Capacitor.getPlatform() === "web"}
        queryClient={QUERY_CLIENT}
        storage={singletonStorage}
        storageAuthKey={STORAGE_AUTH_KEY}
        userPool={userPool}
        userApi={userApi}
        userSyncApi={userSyncApi}
      >
        <App />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

/********* Helper Components *********/
export type User = Awaited<ReturnType<typeof userApi>>;

/**
 * Typed `useAuth` for the Swing Sub user. This should be used vs the
 * swing-components one.
 */
export function useAuth() {
  return _useAuth<User>();
}
