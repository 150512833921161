import { useState } from "react";
import { ChoiceButton } from "swing-components";

import {
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  SchoolRatingValue,
} from "~components";
import { msg } from "~utils";

type ModalContentSchoolFeedbackRatingProps = {
  onNext: (rating: SchoolRatingValue) => void;
  onDismiss: () => void;
  schoolName: string;
  title: string;
};

export function ModalContentSchoolFeedbackRating(props: ModalContentSchoolFeedbackRatingProps) {
  const { onNext, onDismiss, schoolName, title } = props;
  const [selectedRating, setSelectedRating] = useState<SchoolRatingValue>();
  const isNextDisabled = !selectedRating;

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        <div style={{ paddingBottom: "16px" }}>{msg("SCHOOL_FEEDBACK_RATING_MESSAGE")}.</div>
        <div style={{ fontWeight: "var(--swing-font-semibold", paddingBottom: "16px" }}>
          {msg("SCHOOL_FEEDBACK_RATING_QUESTION", {
            schoolName:
              schoolName.length != 0
                ? schoolName
                : msg("SCHOOL_FEEDBACK_RATING_QUESTION_DEFAULT_SCHOOL"),
          })}
          ?
        </div>
        <div style={{ display: "flex", gap: 16 }}>
          <ChoiceButton
            icon={{
              name: "Face Negative",
              color: "black",
            }}
            isSelected={selectedRating === "NEGATIVE"}
            value="NEGATIVE"
            label={FeedbackExperiences["NEGATIVE"]}
            onChange={(rating: string) => setSelectedRating(rating as SchoolRatingValue)}
          />
          <ChoiceButton
            icon={{
              name: "Face Neutral",
              color: "black",
            }}
            isSelected={selectedRating === "NEUTRAL"}
            value="NEUTRAL"
            label={FeedbackExperiences["NEUTRAL"]}
            onChange={(rating: string) => setSelectedRating(rating as SchoolRatingValue)}
          />
          <ChoiceButton
            icon={{
              name: "Face Positive",
              color: "black",
            }}
            isSelected={selectedRating === "POSITIVE"}
            value="POSITIVE"
            label={FeedbackExperiences["POSITIVE"]}
            onChange={(rating: string) => setSelectedRating(rating as SchoolRatingValue)}
          />
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isNextDisabled,
          text: msg("LABEL_NEXT"),
          onClick: () => {
            if (!isNextDisabled) {
              onNext(selectedRating);
            }
          },
        }}
      />
    </ModalSubProfileEdit>
  );
}

export const FeedbackExperiences: Record<SchoolRatingValue, string> = {
  POSITIVE: msg("LABEL_POSITIVE"),
  NEUTRAL: msg("LABEL_NEUTRAL"),
  NEGATIVE: msg("LABEL_NEGATIVE"),
};
