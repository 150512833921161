import { jsx as e, Fragment as m } from "react/jsx-runtime";
import "@ionic/react";
import { e as u, h as p } from "../../../../../AuthProvider-ykImoPqe.js";
import "react";
import "../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { SwingHeroInEligible as d } from "./SwingHeroInEligible/SwingHeroInEligible.js";
function E(l) {
  const {
    subDaysWorked: o,
    score: t,
    isSwingHero: n,
    isHourly: r,
    onClick: i,
    isSwingHeroEligible: s
  } = l, g = o < 5 || t === void 0;
  return /* @__PURE__ */ e(m, {
    children: s === !1 ? /* @__PURE__ */ e(d, {
      isHourly: r,
      onClick: i == null ? void 0 : i.skillBuilderOnClick
    }) : g ? /* @__PURE__ */ e(u, {
      subDaysWorked: o,
      isHourly: r,
      onClick: i == null ? void 0 : i.requestsOnClick
    }) : /* @__PURE__ */ e(p, {
      score: t,
      isSwingHero: n,
      isHourly: r,
      onClick: i == null ? void 0 : i.requestsOnClick
    })
  });
}
export {
  E as SwingHero
};
