import le from "react";
var ce = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function ue(v) {
  return v && v.__esModule && Object.prototype.hasOwnProperty.call(v, "default") ? v.default : v;
}
function ge(v) {
  if (v.__esModule) return v;
  var K = v.default;
  if (typeof K == "function") {
    var j = function w() {
      return this instanceof w ? Reflect.construct(K, arguments, this.constructor) : K.apply(this, arguments);
    };
    j.prototype = K.prototype;
  } else j = {};
  return Object.defineProperty(j, "__esModule", {
    value: !0
  }), Object.keys(v).forEach(function(w) {
    var U = Object.getOwnPropertyDescriptor(v, w);
    Object.defineProperty(j, w, U.get ? U : {
      enumerable: !0,
      get: function() {
        return v[w];
      }
    });
  }), j;
}
var ie = { exports: {} };
(function(v, K) {
  (function(j, w) {
    v.exports = w(le);
  })(ce, (j) => (() => {
    var w = { 703: (p, d, C) => {
      var t = C(414);
      function M() {
      }
      function W() {
      }
      W.resetWarningCache = M, p.exports = function() {
        function m(ne, A, G, Z, re, V) {
          if (V !== t) {
            var z = new Error("Calling PropTypes validators directly is not supported by the `prop-types` package. Use PropTypes.checkPropTypes() to call them. Read more at http://fb.me/use-check-prop-types");
            throw z.name = "Invariant Violation", z;
          }
        }
        function O() {
          return m;
        }
        m.isRequired = m;
        var J = { array: m, bigint: m, bool: m, func: m, number: m, object: m, string: m, symbol: m, any: m, arrayOf: O, element: m, elementType: m, instanceOf: O, node: m, objectOf: O, oneOf: O, oneOfType: O, shape: O, exact: O, checkPropTypes: W, resetWarningCache: M };
        return J.PropTypes = J, J;
      };
    }, 697: (p, d, C) => {
      p.exports = C(703)();
    }, 414: (p) => {
      p.exports = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
    }, 98: (p) => {
      p.exports = j;
    } }, U = {};
    function y(p) {
      var d = U[p];
      if (d !== void 0) return d.exports;
      var C = U[p] = { exports: {} };
      return w[p](C, C.exports, y), C.exports;
    }
    y.n = (p) => {
      var d = p && p.__esModule ? () => p.default : () => p;
      return y.d(d, { a: d }), d;
    }, y.d = (p, d) => {
      for (var C in d) y.o(d, C) && !y.o(p, C) && Object.defineProperty(p, C, { enumerable: !0, get: d[C] });
    }, y.o = (p, d) => Object.prototype.hasOwnProperty.call(p, d), y.r = (p) => {
      typeof Symbol < "u" && Symbol.toStringTag && Object.defineProperty(p, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(p, "__esModule", { value: !0 });
    };
    var ee = {};
    return (() => {
      y.r(ee), y.d(ee, { default: () => se });
      var p = y(98), d = y.n(p), C = y(697), t = y.n(C);
      function M() {
        return M = Object.assign ? Object.assign.bind() : function(n) {
          for (var i = 1; i < arguments.length; i++) {
            var o = arguments[i];
            for (var l in o) Object.prototype.hasOwnProperty.call(o, l) && (n[l] = o[l]);
          }
          return n;
        }, M.apply(this, arguments);
      }
      var W = function(n) {
        var i = n.pageClassName, o = n.pageLinkClassName, l = n.page, I = n.selected, H = n.activeClassName, E = n.activeLinkClassName, a = n.getEventListener, e = n.pageSelectedHandler, u = n.href, r = n.extraAriaContext, s = n.pageLabelBuilder, c = n.rel, f = n.ariaLabel || "Page " + l + (r ? " " + r : ""), h = null;
        return I && (h = "page", f = n.ariaLabel || "Page " + l + " is your current page", i = i !== void 0 ? i + " " + H : H, o !== void 0 ? E !== void 0 && (o = o + " " + E) : o = E), d().createElement("li", { className: i }, d().createElement("a", M({ rel: c, role: u ? void 0 : "button", className: o, href: u, tabIndex: I ? "-1" : "0", "aria-label": f, "aria-current": h, onKeyPress: e }, a(e)), s(l)));
      };
      W.propTypes = { pageSelectedHandler: t().func.isRequired, selected: t().bool.isRequired, pageClassName: t().string, pageLinkClassName: t().string, activeClassName: t().string, activeLinkClassName: t().string, extraAriaContext: t().string, href: t().string, ariaLabel: t().string, page: t().number.isRequired, getEventListener: t().func.isRequired, pageLabelBuilder: t().func.isRequired, rel: t().string };
      const m = W;
      function O() {
        return O = Object.assign ? Object.assign.bind() : function(n) {
          for (var i = 1; i < arguments.length; i++) {
            var o = arguments[i];
            for (var l in o) Object.prototype.hasOwnProperty.call(o, l) && (n[l] = o[l]);
          }
          return n;
        }, O.apply(this, arguments);
      }
      var J = function(n) {
        var i = n.breakLabel, o = n.breakAriaLabel, l = n.breakClassName, I = n.breakLinkClassName, H = n.breakHandler, E = n.getEventListener, a = l || "break";
        return d().createElement("li", { className: a }, d().createElement("a", O({ className: I, role: "button", tabIndex: "0", "aria-label": o, onKeyPress: H }, E(H)), i));
      };
      J.propTypes = { breakLabel: t().oneOfType([t().string, t().node]), breakAriaLabel: t().string, breakClassName: t().string, breakLinkClassName: t().string, breakHandler: t().func.isRequired, getEventListener: t().func.isRequired };
      const ne = J;
      function A(n) {
        var i = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
        return n ?? i;
      }
      function G(n) {
        return G = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(i) {
          return typeof i;
        } : function(i) {
          return i && typeof Symbol == "function" && i.constructor === Symbol && i !== Symbol.prototype ? "symbol" : typeof i;
        }, G(n);
      }
      function Z() {
        return Z = Object.assign ? Object.assign.bind() : function(n) {
          for (var i = 1; i < arguments.length; i++) {
            var o = arguments[i];
            for (var l in o) Object.prototype.hasOwnProperty.call(o, l) && (n[l] = o[l]);
          }
          return n;
        }, Z.apply(this, arguments);
      }
      function re(n, i) {
        for (var o = 0; o < i.length; o++) {
          var l = i[o];
          l.enumerable = l.enumerable || !1, l.configurable = !0, "value" in l && (l.writable = !0), Object.defineProperty(n, l.key, l);
        }
      }
      function V(n, i) {
        return V = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(o, l) {
          return o.__proto__ = l, o;
        }, V(n, i);
      }
      function z(n, i) {
        if (i && (G(i) === "object" || typeof i == "function")) return i;
        if (i !== void 0) throw new TypeError("Derived constructors may only return object or undefined");
        return x(n);
      }
      function x(n) {
        if (n === void 0) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return n;
      }
      function Q(n) {
        return Q = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(i) {
          return i.__proto__ || Object.getPrototypeOf(i);
        }, Q(n);
      }
      function P(n, i, o) {
        return i in n ? Object.defineProperty(n, i, { value: o, enumerable: !0, configurable: !0, writable: !0 }) : n[i] = o, n;
      }
      var te = function(n) {
        (function(a, e) {
          if (typeof e != "function" && e !== null) throw new TypeError("Super expression must either be null or a function");
          a.prototype = Object.create(e && e.prototype, { constructor: { value: a, writable: !0, configurable: !0 } }), Object.defineProperty(a, "prototype", { writable: !1 }), e && V(a, e);
        })(E, n);
        var i, o, l, I, H = (l = E, I = function() {
          if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham) return !1;
          if (typeof Proxy == "function") return !0;
          try {
            return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
            })), !0;
          } catch {
            return !1;
          }
        }(), function() {
          var a, e = Q(l);
          if (I) {
            var u = Q(this).constructor;
            a = Reflect.construct(e, arguments, u);
          } else a = e.apply(this, arguments);
          return z(this, a);
        });
        function E(a) {
          var e, u;
          return function(r, s) {
            if (!(r instanceof s)) throw new TypeError("Cannot call a class as a function");
          }(this, E), P(x(e = H.call(this, a)), "handlePreviousPage", function(r) {
            var s = e.state.selected;
            e.handleClick(r, null, s > 0 ? s - 1 : void 0, { isPrevious: !0 });
          }), P(x(e), "handleNextPage", function(r) {
            var s = e.state.selected, c = e.props.pageCount;
            e.handleClick(r, null, s < c - 1 ? s + 1 : void 0, { isNext: !0 });
          }), P(x(e), "handlePageSelected", function(r, s) {
            if (e.state.selected === r) return e.callActiveCallback(r), void e.handleClick(s, null, void 0, { isActive: !0 });
            e.handleClick(s, null, r);
          }), P(x(e), "handlePageChange", function(r) {
            e.state.selected !== r && (e.setState({ selected: r }), e.callCallback(r));
          }), P(x(e), "getEventListener", function(r) {
            return P({}, e.props.eventListener, r);
          }), P(x(e), "handleClick", function(r, s, c) {
            var f = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {}, h = f.isPrevious, R = h !== void 0 && h, F = f.isNext, Y = F !== void 0 && F, q = f.isBreak, L = q !== void 0 && q, T = f.isActive, _ = T !== void 0 && T;
            r.preventDefault ? r.preventDefault() : r.returnValue = !1;
            var B = e.state.selected, g = e.props.onClick, N = c;
            if (g) {
              var k = g({ index: s, selected: B, nextSelectedPage: c, event: r, isPrevious: R, isNext: Y, isBreak: L, isActive: _ });
              if (k === !1) return;
              Number.isInteger(k) && (N = k);
            }
            N !== void 0 && e.handlePageChange(N);
          }), P(x(e), "handleBreakClick", function(r, s) {
            var c = e.state.selected;
            e.handleClick(s, r, c < r ? e.getForwardJump() : e.getBackwardJump(), { isBreak: !0 });
          }), P(x(e), "callCallback", function(r) {
            e.props.onPageChange !== void 0 && typeof e.props.onPageChange == "function" && e.props.onPageChange({ selected: r });
          }), P(x(e), "callActiveCallback", function(r) {
            e.props.onPageActive !== void 0 && typeof e.props.onPageActive == "function" && e.props.onPageActive({ selected: r });
          }), P(x(e), "getElementPageRel", function(r) {
            var s = e.state.selected, c = e.props, f = c.nextPageRel, h = c.prevPageRel, R = c.selectedPageRel;
            return s - 1 === r ? h : s === r ? R : s + 1 === r ? f : void 0;
          }), P(x(e), "pagination", function() {
            var r = [], s = e.props, c = s.pageRangeDisplayed, f = s.pageCount, h = s.marginPagesDisplayed, R = s.breakLabel, F = s.breakClassName, Y = s.breakLinkClassName, q = s.breakAriaLabels, L = e.state.selected;
            if (f <= c) for (var T = 0; T < f; T++) r.push(e.getPageElement(T));
            else {
              var _ = c / 2, B = c - _;
              L > f - c / 2 ? _ = c - (B = f - L) : L < c / 2 && (B = c - (_ = L));
              var g, N, k = function(S) {
                return e.getPageElement(S);
              }, b = [];
              for (g = 0; g < f; g++) {
                var X = g + 1;
                if (X <= h) b.push({ type: "page", index: g, display: k(g) });
                else if (X > f - h) b.push({ type: "page", index: g, display: k(g) });
                else if (g >= L - _ && g <= L + (L === 0 && c > 1 ? B - 1 : B)) b.push({ type: "page", index: g, display: k(g) });
                else if (R && b.length > 0 && b[b.length - 1].display !== N && (c > 0 || h > 0)) {
                  var ae = g < L ? q.backward : q.forward;
                  N = d().createElement(ne, { key: g, breakAriaLabel: ae, breakLabel: R, breakClassName: F, breakLinkClassName: Y, breakHandler: e.handleBreakClick.bind(null, g), getEventListener: e.getEventListener }), b.push({ type: "break", index: g, display: N });
                }
              }
              b.forEach(function(S, D) {
                var $ = S;
                S.type === "break" && b[D - 1] && b[D - 1].type === "page" && b[D + 1] && b[D + 1].type === "page" && b[D + 1].index - b[D - 1].index <= 2 && ($ = { type: "page", index: S.index, display: k(S.index) }), r.push($.display);
              });
            }
            return r;
          }), a.initialPage !== void 0 && a.forcePage !== void 0 && console.warn("(react-paginate): Both initialPage (".concat(a.initialPage, ") and forcePage (").concat(a.forcePage, ") props are provided, which is discouraged.") + ` Use exclusively forcePage prop for a controlled component.
See https://reactjs.org/docs/forms.html#controlled-components`), u = a.initialPage ? a.initialPage : a.forcePage ? a.forcePage : 0, e.state = { selected: u }, e;
        }
        return i = E, (o = [{ key: "componentDidMount", value: function() {
          var a = this.props, e = a.initialPage, u = a.disableInitialCallback, r = a.extraAriaContext, s = a.pageCount, c = a.forcePage;
          e === void 0 || u || this.callCallback(e), r && console.warn("DEPRECATED (react-paginate): The extraAriaContext prop is deprecated. You should now use the ariaLabelBuilder instead."), Number.isInteger(s) || console.warn("(react-paginate): The pageCount prop value provided is not an integer (".concat(s, "). Did you forget a Math.ceil()?")), e !== void 0 && e > s - 1 && console.warn("(react-paginate): The initialPage prop provided is greater than the maximum page index from pageCount prop (".concat(e, " > ").concat(s - 1, ").")), c !== void 0 && c > s - 1 && console.warn("(react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (".concat(c, " > ").concat(s - 1, ")."));
        } }, { key: "componentDidUpdate", value: function(a) {
          this.props.forcePage !== void 0 && this.props.forcePage !== a.forcePage && (this.props.forcePage > this.props.pageCount - 1 && console.warn("(react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (".concat(this.props.forcePage, " > ").concat(this.props.pageCount - 1, ").")), this.setState({ selected: this.props.forcePage })), Number.isInteger(a.pageCount) && !Number.isInteger(this.props.pageCount) && console.warn("(react-paginate): The pageCount prop value provided is not an integer (".concat(this.props.pageCount, "). Did you forget a Math.ceil()?"));
        } }, { key: "getForwardJump", value: function() {
          var a = this.state.selected, e = this.props, u = e.pageCount, r = a + e.pageRangeDisplayed;
          return r >= u ? u - 1 : r;
        } }, { key: "getBackwardJump", value: function() {
          var a = this.state.selected - this.props.pageRangeDisplayed;
          return a < 0 ? 0 : a;
        } }, { key: "getElementHref", value: function(a) {
          var e = this.props, u = e.hrefBuilder, r = e.pageCount, s = e.hrefAllControls;
          if (u) return s || a >= 0 && a < r ? u(a + 1, r, this.state.selected) : void 0;
        } }, { key: "ariaLabelBuilder", value: function(a) {
          var e = a === this.state.selected;
          if (this.props.ariaLabelBuilder && a >= 0 && a < this.props.pageCount) {
            var u = this.props.ariaLabelBuilder(a + 1, e);
            return this.props.extraAriaContext && !e && (u = u + " " + this.props.extraAriaContext), u;
          }
        } }, { key: "getPageElement", value: function(a) {
          var e = this.state.selected, u = this.props, r = u.pageClassName, s = u.pageLinkClassName, c = u.activeClassName, f = u.activeLinkClassName, h = u.extraAriaContext, R = u.pageLabelBuilder;
          return d().createElement(m, { key: a, pageSelectedHandler: this.handlePageSelected.bind(null, a), selected: e === a, rel: this.getElementPageRel(a), pageClassName: r, pageLinkClassName: s, activeClassName: c, activeLinkClassName: f, extraAriaContext: h, href: this.getElementHref(a), ariaLabel: this.ariaLabelBuilder(a), page: a + 1, pageLabelBuilder: R, getEventListener: this.getEventListener });
        } }, { key: "render", value: function() {
          var a = this.props.renderOnZeroPageCount;
          if (this.props.pageCount === 0 && a !== void 0) return a && a(this.props);
          var e = this.props, u = e.disabledClassName, r = e.disabledLinkClassName, s = e.pageCount, c = e.className, f = e.containerClassName, h = e.previousLabel, R = e.previousClassName, F = e.previousLinkClassName, Y = e.previousAriaLabel, q = e.prevRel, L = e.nextLabel, T = e.nextClassName, _ = e.nextLinkClassName, B = e.nextAriaLabel, g = e.nextRel, N = this.state.selected, k = N === 0, b = N === s - 1, X = "".concat(A(R)).concat(k ? " ".concat(A(u)) : ""), ae = "".concat(A(T)).concat(b ? " ".concat(A(u)) : ""), S = "".concat(A(F)).concat(k ? " ".concat(A(r)) : ""), D = "".concat(A(_)).concat(b ? " ".concat(A(r)) : ""), $ = k ? "true" : "false", oe = b ? "true" : "false";
          return d().createElement("ul", { className: c || f, role: "navigation", "aria-label": "Pagination" }, d().createElement("li", { className: X }, d().createElement("a", Z({ className: S, href: this.getElementHref(N - 1), tabIndex: k ? "-1" : "0", role: "button", onKeyPress: this.handlePreviousPage, "aria-disabled": $, "aria-label": Y, rel: q }, this.getEventListener(this.handlePreviousPage)), h)), this.pagination(), d().createElement("li", { className: ae }, d().createElement("a", Z({ className: D, href: this.getElementHref(N + 1), tabIndex: b ? "-1" : "0", role: "button", onKeyPress: this.handleNextPage, "aria-disabled": oe, "aria-label": B, rel: g }, this.getEventListener(this.handleNextPage)), L)));
        } }]) && re(i.prototype, o), Object.defineProperty(i, "prototype", { writable: !1 }), E;
      }(p.Component);
      P(te, "propTypes", { pageCount: t().number.isRequired, pageRangeDisplayed: t().number, marginPagesDisplayed: t().number, previousLabel: t().node, previousAriaLabel: t().string, prevPageRel: t().string, prevRel: t().string, nextLabel: t().node, nextAriaLabel: t().string, nextPageRel: t().string, nextRel: t().string, breakLabel: t().oneOfType([t().string, t().node]), breakAriaLabels: t().shape({ forward: t().string, backward: t().string }), hrefBuilder: t().func, hrefAllControls: t().bool, onPageChange: t().func, onPageActive: t().func, onClick: t().func, initialPage: t().number, forcePage: t().number, disableInitialCallback: t().bool, containerClassName: t().string, className: t().string, pageClassName: t().string, pageLinkClassName: t().string, pageLabelBuilder: t().func, activeClassName: t().string, activeLinkClassName: t().string, previousClassName: t().string, nextClassName: t().string, previousLinkClassName: t().string, nextLinkClassName: t().string, disabledClassName: t().string, disabledLinkClassName: t().string, breakClassName: t().string, breakLinkClassName: t().string, extraAriaContext: t().string, ariaLabelBuilder: t().func, eventListener: t().string, renderOnZeroPageCount: t().func, selectedPageRel: t().string }), P(te, "defaultProps", { pageRangeDisplayed: 2, marginPagesDisplayed: 3, activeClassName: "selected", previousLabel: "Previous", previousClassName: "previous", previousAriaLabel: "Previous page", prevPageRel: "prev", prevRel: "prev", nextLabel: "Next", nextClassName: "next", nextAriaLabel: "Next page", nextPageRel: "next", nextRel: "next", breakLabel: "...", breakAriaLabels: { forward: "Jump forward", backward: "Jump backward" }, disabledClassName: "disabled", disableInitialCallback: !1, pageLabelBuilder: function(n) {
        return n;
      }, eventListener: "onClick", renderOnZeroPageCount: void 0, selectedPageRel: "canonical", hrefAllControls: !1 });
      const se = te;
    })(), ee;
  })());
})(ie);
var pe = ie.exports;
const fe = /* @__PURE__ */ ue(pe);
export {
  fe as R,
  ge as a,
  ce as c,
  ue as g
};
