const I = {
  ALERT_BOX_CANCEL_REQ_LABEL_LAST_MIN_WARNING: () => "Cancelling now is considered last minute and a sub is assigned to this request. If you cancel, you will still be invoiced based on your",
  ALERT_BOX_CANCEL_REQ_LABEL_CANCELLATION_POLICY: () => "cancellation policy",
  ALERT_BOX_CANCEL_WINDOW_LABEL: () => "Within cancellation window",
  EMAIL_FIELD_LABEL_CONTACT_SUPPORT: () => "contact support",
  EMAIL_FIELD_LOGIN_INPUT_ARIA_EDIT_LABEL: () => "Edit email",
  EMAIL_FIELD_LOGIN_INPUT_PLACEHOLDER: () => "Email",
  EMAIL_FIELD_LOGIN_UNRECOGNIZED_EMAIL: () => "We don't recognize this email. Try another or ",
  FORGOT_PASSWORD_LABEL_BACK: () => "Back",
  FORGOT_PASSWORD_LOGIN_ERROR_TITLE: () => "Something went wrong",
  FORGOT_PASSWORD_RESET_BACK_TO_LOGIN_ARIA_MESSAGE: () => "Back to Login page",
  FORGOT_PASSWORD_RESET_PASSWORD_BANNER_MESSAGE: ({
    email: _
  }) => `Enter the verification code sent to ${_}. Emails may take up to 15 minutes to arrive`,
  FORGOT_PASSWORD_RESET_PASSWORD_FIELD_LABEL: () => "New Password",
  FORGOT_PASSWORD_RESET_PASSWORD_HEADER_LABEL: () => "Password Reset",
  FORGOT_PASSWORD_RESET_PASSWORD_INVALID_CODE_MESSAGE: () => "Please enter a valid verification code",
  FORGOT_PASSWORD_RESET_PASSWORD_SUBMIT_LABEL: () => "Reset Password",
  FORGOT_PASSWORD_RESET_PASSWORD_VERIFICATION_LABEL: () => "Verification Code",
  FORGOT_PASSWORD_RESET_VALIDATION_LOWERCASE: () => "1 lowercase character",
  FORGOT_PASSWORD_RESET_VALIDATION_MIN_CHARACTERS: () => "8 characters minimum",
  FORGOT_PASSWORD_RESET_VALIDATION_NUMBER: () => "1 number",
  FORGOT_PASSWORD_RESET_VALIDATION_UPPERCASE: () => "1 uppercase character",
  FORGOT_PASSWORD_RESET_VERIFICATION_CODE_EXPIRED_MESSAGE: () => "Select Forgot Password on the previous screen for a new code",
  FORGOT_PASSWORD_RESET_VERIFICATION_CODE_EXPIRED_TITLE: () => "Your verification code has expired",
  GOOGLE_LOGIN_UNRECOGNIZED_EMAIL: () => "We don't recognize this Google user. Try another or ",
  LOGIN_EMAIL_ENTRY_LOGIN_MESSAGE: () => "Log in as a substitute teacher",
  LOGIN_EMAIL_ENTRY_LOGIN_LABEL_ENTER_PASSWORD: () => "Enter Password",
  LOGIN_EMAIL_ENTRY_LABEL_OR: () => "OR",
  LOGIN_EMAIL_ENTRY_LOGIN_LABEL_CONTINUE_WITH_GOOGLE: () => "Continue with Google",
  LOGIN_FOOTER_LOGIN_LABEL_NEED_HELP: () => "Need help? Email",
  LOGIN_FOOTER_SUPPORT_EMAIL: () => "support@swingeducation.com",
  LOGIN_FORM_CONTAINER_SWING_LOGO_ALT_TEXT: () => "Swing Logo",
  LOGIN_INCORRECT_USER_TYPE: () => "Looks like you're a school admin. Try logging into our",
  LOGIN_INCORRECT_USER_TYPE_LABEL_MOBILE: () => "Admin app",
  LOGIN_INCORRECT_USER_TYPE_LABEL_WEB: () => "Admin website",
  LOGIN_LAYOUT_SPLASH_IMAGE_ALT_TEXT: () => "Stock image of a substitute teacher in a classroom",
  LOGIN_WITH_PASSWORD_LABEL_CONTACT_SUPPORT: () => "contact support",
  LOGIN_WITH_PASSWORD_LOGIN_ATTEMPTS_EXCEEDED: () => "Your account has been temporarily locked. Try again in 15 minutes.",
  LOGIN_WITH_PASSWORD_LOGIN_BUTTON_LABEL: () => "Log In",
  LOGIN_WITH_PASSWORD_LOGIN_ERROR_MESSAGE_1: () => "Please try again later or",
  LOGIN_WITH_PASSWORD_LOGIN_ERROR_MESSAGE_2: () => "for assistance",
  LOGIN_WITH_PASSWORD_LOGIN_FORGOT_PASSWORD_LABEL: () => "Forgot Password",
  LOGIN_WITH_PASSWORD_LOGIN_ERROR_TITLE: () => "Something went wrong",
  LOGIN_WITH_PASSWORD_LOGIN_INCORRECT_PASSWORD: () => "Sorry, we don't recognize that password.",
  LOGIN_WITH_PASSWORD_LOGIN_PASSWORD_LABEL: () => "Password",
  LOGIN_WITH_PASSWORD_RESET_PASSWORD_SUCCESS_MESSAGE: () => "We successfully reset your password. Log in with your new password",
  LOGIN_WITH_PASSWORD_LOGIN_WELCOME_BACK_MESSAGE: () => "Welcome back",
  PASSWORD_FIELD_LOGIN_PASSWORD_SHOW_LABEL: () => "Show",
  PASSWORD_FIELD_LOGIN_PASSWORD_HIDE_LABEL: () => "Hide",
  PASSWORD_FIELD_LOGIN_PASSWORD_ARIA_SHOW_LABEL: () => "Show password",
  PASSWORD_FIELD_LOGIN_PASSWORD_ARIA_HIDE_LABEL: () => "Hide password",
  PASSWORD_VALIDATION_FIELD_RESET_PASSWORD_VALIDATION_LABEL: () => "All passwords must have",
  SUB_PROFILE_ABOUT_EDIT_EMPTY: () => "This is your time to shine! Subs who fill out their profile are more likely to be accepted for long-term requests",
  SUB_PROFILE_ABOUT_HEADER: () => "About Me",
  SUB_PROFILE_CLASSROOM_PREFERENCES_HEADER: () => "Classroom Preferences",
  SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_GRADE_LEVEL: () => "Grade Level",
  SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_LANGUAGES: () => "Languages",
  SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_SUBJECTS: () => "Subjects",
  SUB_PROFILE_CONTACT_IMAGE_ALT: () => "generic avatar user icon",
  SUB_PROFILE_CONTACT_EDIT_BUTTON: () => "Edit",
  SUB_PROFILE_CONTACT_SWING: () => "Swing",
  SUB_PROFILE_CONTACT_HERO: () => "Hero",
  SUB_PROFILE_EDUCATION_EDIT_EMPTY: () => "Showcase your education",
  SUB_PROFILE_EDUCATION_HEADER: () => "Education",
  SUB_PROFILE_FEEDBACK_FROM_SCHOOLS_HEADER: () => "Feedback from Schools",
  SUB_PROFILE_QUALIFICATIONS_HEADER: () => "Qualifications",
  SUB_PROFILE_QUALIFICATIONS_SKILL_BUILDER_SUB_LABEL: () => "Skill Builder",
  SUB_PROFILE_QUALIFICATIONS_STAR_SUB_LABEL: () => "Star Sub",
  SUB_PROFILE_NO_INFORMATION: () => "No information provided",
  SUB_PROFILE_RELEVANT_WORK_EXPERIENCE_BUTTON_TEXT: () => "Add Another Experience",
  SUB_PROFILE_RELEVANT_WORK_EXPERIENCE_EDIT_EMPTY: () => "Showcase your work experience",
  SUB_PROFILE_RELEVANT_WORK_EXPERIENCE_HEADER: () => "Work Experience",
  SUB_PROFILE_SWING_EXPERIENCE_DAYS_WORKED: () => "days worked",
  SUB_PROFILE_SWING_EXPERIENCE_HEADER: () => "Swing Experience",
  SUB_PROFILE_SWING_EXPERIENCE_POSITIVE_REVIEWS: () => "positive reviews",
  SUB_PROFILE_SWING_EXPERIENCE_SCHOOLS_WORKED: () => "schools worked",
  SUB_PROFILE_SWING_EXPERIENCE_TIMES_PREFERRED: () => "times preferred",
  SUB_PROFILE_NO_IMAGE_TEXT: () => "Upload a picture to increase your chances of being selected by a school",
  SWING_HERO_ELIGIBLE_HERO_PERKS_LIST_TITLE: () => "You're qualifying for",
  SWING_HERO_ELIGIBLE_HERO_SCORE_LIST_TITLE: () => "How to keep your status",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_BUTTON_LABEL: () => "Find Requests",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_EARN_HIGH_SCORE_LIST_FIRST_ITEM: () => "Show up on time",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_EARN_HIGH_SCORE_LIST_SECOND_ITEM: () => "Follow school policies",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_EARN_HIGH_SCORE_LIST_THIRD_ITEM: () => "Be professional and adaptable",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_PERKS_LIST_FIRST_ITEM: () => "Priority access for certain requests",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_PERKS_LIST_SECOND_ITEM: () => "Increased earning potential",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_PERKS_LIST_THIRD_ITEM: () => "Profile badge",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_SUPPORT_ARTICLE_LINK_W2: () => "/hc/en-us/articles/34892536707731-Swing-Heroes-for-subs-in-CA",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_SUPPORT_ARTICLE_LINK_1099: () => "/hc/en-us/articles/34892654096787-Swing-Heroes-for-subs-in-NY-NJ-DC-IL-TX-and-AZ",
  SWING_HERO_ELIGIBLE_NOT_YET_ELIGIBLE_TITLE: () => "Swing Hero Progress",
  SWING_HERO_ELIGIBLE_PERKS_LIST_TITLE: () => "To qualify for",
  SWING_HERO_ELIGIBLE_SCORE_LIST_TITLE: () => "Raise your score",
  SWING_HERO_SWING_SUB: () => "Swing Sub",
  SWING_HERO_ELIGIBLE_STATUS_SUB_TEXT: () => "Scores updated weekly.",
  SWING_HERO_ELIGIBLE_SUB_TEXT_BOLD: () => "95 or higher",
  SWING_HERO_ELIGIBLE_SUB_TEXT_END: ({
    isSwingHero: _
  }) => `to ${_ ? "keep your" : "gain"} Swing Hero status.`,
  SWING_HERO_ELIGIBLE_SUB_TEXT_START: ({
    isSwingHero: _
  }) => `${_ ? "Maintain" : "Earn"} a score of`,
  SWING_HEROES: () => "Swing Heroes",
  SWING_HERO_INELIGIBLE_CONTACT_SUPPORT_START: () => "Contact support",
  SWING_HERO_INELIGIBLE_CONTACT_SUPPORT_END: () => "if you have any questions or want additional details.",
  SWING_HERO_INELIGIBLE_STATUS_SUB_TEXT: () => "At this time you are ineligible for Swing Hero status due to a no-show or a negative feedback incident.",
  SWING_HERO_INELIGIBLE_LIST_TITLE: () => "Tips to get back on track",
  SWING_HERO_INELIGIBLE_LIST_FIRST_ITEM: () => "Work and receive positive feedback",
  SWING_HERO_INELIGIBLE_LIST_SECOND_ITEM: () => "Show up on time and ready to teach",
  SWING_HERO_INELIGIBLE_LIST_SKILL_BUILDERS_PREFIX: () => "Complete",
  SWING_HERO_INELIGIBLE_LIST_SKILL_BUILDERS_BUTTON: () => "Skill Builders",
  SWING_HERO_INELIGIBLE_LIST_SKILL_BUILDERS_SUFFIX: () => "to improve your skills.",
  SWING_HERO_NOT_YET_ELIGIBLE_EARN_HIGH_SCORE_LIST_TITLE: () => "How to earn a high score",
  SWING_HERO_LEARN_MORE_ABOUT: () => "Learn more about",
  SWING_HERO_NOT_YET_ELIGIBLE_PERKS_LIST_TITLE: () => "Swing Hero perks",
  SWING_HERO_NOT_YET_ELIGIBLE_PROGRESS_BAR_COMPLETED: () => "Score available soon!",
  SWING_HERO_NOT_YET_ELIGIBLE_PROGRESS_BAR_INITIAL_TITLE: () => "Work 5 days to view your score",
  SWING_HERO_NOT_YET_ELIGIBLE_PROGRESS_BAR_SUB_TEXT: () => "Calculating your score. Scores updated weekly.",
  SWING_HERO_NOT_YET_ELIGIBLE_PROGRESS_BAR_TITLE: (_) => `Work ${_} more ${_ === 1 ? "day" : "days"} to view your score`,
  SWING_HERO_NOT_YET_ELIGIBLE_SUB_TEXT: () => "Work 5 days to view your score. Earn a score of 95 or higher to become a Swing Hero.",
  SWING_HERO_SCORE_PROGRESS_TITLE: () => "Your Score",
  SWING_HERO_YOUR_STATUS: () => "Your Status",
  /** Aria labels **/
  ARIA_LABEL_SELECTED: () => "selected",
  ARIA_LABEL_DESELECTED: () => "deselected",
  /** Labels **/
  LABEL_SELECT: () => "Select",
  LABEL_SWING_LOADING_ALT: () => "Swing Loading Icon",
  LABEL_ALIASED: () => "Aliased as"
};
function L(_, ...E) {
  return I[_](E[0]);
}
export {
  L as default
};
