import { jsxs as i, Fragment as f, jsx as n } from "react/jsx-runtime";
import { Colors as s } from "../../foundations/Colors/Colors.js";
import { I as g } from "../../../Icon-p8J22jb2.js";
import "../../../AuthProvider-ykImoPqe.js";
import "@ionic/react";
import { useState as u } from "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
function w(p) {
  const {
    title: d,
    chip: r,
    children: a,
    isOpen: t,
    onToggle: o
  } = p, [c, m] = u(t ?? !1), l = t !== void 0, e = l ? t : c, h = () => {
    l ? o == null || o() : m((x) => !x);
  };
  return /* @__PURE__ */ i(f, {
    children: [/* @__PURE__ */ n("div", {
      style: {
        padding: "16px 0",
        backgroundColor: s.white200,
        borderBottom: `1px solid ${s.slate300}`
      },
      children: /* @__PURE__ */ i("div", {
        style: {
          padding: "8px 16px",
          display: "flex",
          justifyContent: "space-between"
        },
        children: [/* @__PURE__ */ i("div", {
          style: {
            display: "flex",
            gap: 16,
            alignItems: "center"
          },
          children: [/* @__PURE__ */ n("span", {
            style: {
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontSize: 20
            },
            children: d
          }), r && r]
        }), /* @__PURE__ */ n("button", {
          style: {
            background: "none"
          },
          onClick: h,
          "aria-expanded": e,
          "aria-label": e ? "Collapse section" : "Expand section",
          children: /* @__PURE__ */ n(g, {
            name: e ? "Chevron Down" : "Chevron Up"
          })
        })]
      })
    }), e && a]
  });
}
export {
  w as Accordion
};
